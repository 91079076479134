var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Service Accounts")]},proxy:true},{key:"subtitle",fn:function(){return [_c('span',[_vm._v(" Manage service accounts and their API keys ")])]},proxy:true},{key:"cta",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","large":"","disabled":!_vm.roles || _vm.roles.length === 0,"data-cy":"invite-service-account"},on:{"click":function($event){_vm.dialogAddServiceAccount = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" person_add ")]),_vm._v(" Add Service Account ")],1)]},proxy:true}])},[_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-text-field',{staticClass:"rounded-0 elevation-1 mb-1",attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search by name","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.searchInput),callback:function ($$v) {_vm.searchInput=$$v},expression:"searchInput"}}):_vm._e(),_c('ServiceAccountsTable',{attrs:{"role-color-map":_vm.roleColorMap,"role-map":_vm.roleMap,"search":_vm.searchInput,"tenant":_vm.tenant,"refetch-signal":_vm.serviceAccountsSignal},on:{"successful-action":function($event){return _vm.handleAlert('success', $event)},"failed-action":function($event){return _vm.handleAlert('error', $event)},"update":_vm.updateRole}})],1)],1),_c('ConfirmDialog',{attrs:{"title":_vm.titleText,"confirm-text":_vm.confirmText,"error":_vm.accountCreationError,"loading":_vm.isCreatingServiceUser || _vm.isUpdatingServiceUser,"disabled":!_vm.serviceAccountFormValid ||
        _vm.isCreatingServiceUser ||
        _vm.isUpdatingServiceUser,"dialog-props":{
      'max-width': '600'
    }},on:{"cancel":_vm.resetServiceAccountDialog,"confirm":_vm.handleAddOrUpdate},model:{value:(_vm.dialogAddServiceAccount),callback:function ($$v) {_vm.dialogAddServiceAccount=$$v},expression:"dialogAddServiceAccount"}},[_c('v-form',{ref:"service-user-form",on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.serviceAccountFormValid),callback:function ($$v) {_vm.serviceAccountFormValid=$$v},expression:"serviceAccountFormValid"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"autofocus":"","disabled":_vm.updateAccountRole,"label":"Account Name","data-cy":"service-account","prepend-icon":"engineering","outlined":"","rules":[_vm.rules.required]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addServiceAccount($event)}},model:{value:(_vm.serviceAccountNameInput),callback:function ($$v) {_vm.serviceAccountNameInput=$$v},expression:"serviceAccountNameInput"}}),_c('v-select',{attrs:{"data-public":"","outlined":"","menu-props":{ offsetY: true },"label":"Role","data-cy":"invite-role","prepend-icon":"supervised_user_circle","items":_vm.filteredRoles,"rules":[_vm.rules.required],"item-text":"name","item-value":"id","item-disabled":"disabled"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roleMap[item.name] ? _vm.roleMap[item.name] : item.name)+" ")]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roleMap[item.name] ? _vm.roleMap[item.name] : item.name)+" ")]}}]),model:{value:(_vm.roleInput),callback:function ($$v) {_vm.roleInput=$$v},expression:"roleInput"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }